define('ember-collapsible-panel/components/cp-panel/component', ['exports', 'ember-collapsible-panel/components/cp-panel/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _template.default,

    panelActions: Ember.inject.service(),
    dependencyChecker: Ember.inject.service(),
    shouldAnimate: Ember.computed.and('dependencyChecker.hasLiquidFire', 'animate'),

    disabled: false,

    group: null, // passed in if rendered as part of a {{cp-panels}} group

    classNames: ['cp-Panel'],
    classNameBindings: ['isOpen:cp-is-open:cp-is-closed', 'disabled:cp-is-disabled'],

    // Caller can overwrite
    name: Ember.computed.oneWay('elementId'),

    panelState: Ember.computed('name', function () {
      var name = this.get('name');
      return this.get('panelActions.state.' + name);
    }),

    isOpen: Ember.computed.readOnly('panelState.isOpen'),
    isClosed: Ember.computed.not('isOpen'),

    panelsWrapper: null,
    animate: true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // If caller passes in open=, use it
      if (this.get('open') !== undefined) {
        this.set('panelState.boundOpenState', this.get('open'));
      }
    },


    // Register with parent panels component
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', function () {
        var group = _this.get('group');

        if (group) {
          _this.get('panelState').set('group', group);
        }
      });
    },


    // Custom action called when toggling that can be provided by caller
    didToggle: function didToggle() {},


    actions: {
      toggleIsOpen: function toggleIsOpen() {
        if (this.get("disabled")) {
          return;
        }
        var name = this.get('name');

        this.get('panelActions').toggle(name);

        this.didToggle(name);
      }
    }
  });
});