define("ember-select/components/select-dropdown-option", ["exports", "ember-select/templates/components/select-dropdown-option"], function (_exports, _selectDropdownOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _selectDropdownOption.default,
    classNames: ['es-option'],
    classNameBindings: ['model.isSelected:es-highlight'],
    click: function click() {
      this.select(this.get('model'));
    },
    mouseEnter: function mouseEnter() {
      this.hover(this.get('model'));
    }
  });

  _exports.default = _default;
});