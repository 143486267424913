
$(window).scroll(function() {
	if ($(this).scrollTop() >= 600) {        
    $('.flecha-arriba').fadeIn(200);
    $('#navbar').addClass('scrolled');    
  } else {
    $('.flecha-arriba').fadeOut(200);   
    $('#navbar').removeClass('scrolled');    
  }
});
$('.flecha-arriba').click(function() {      
	$('body,html').animate({scrollTop : 0});
});
